<template>
    <div>
      <div id="header-image">
        <div id="bg-image"></div>
        <div id="overlay"></div>
      </div>

      <div id="user-bar">
        <div id="greetings">Welcome, {{ userFirstName }}!</div>

        <div class="mkt-bar">
          <div id="plan-group">
            <div :style="hasNoPlanLink" id="current-plan">Account Type</div>
            <div id="plan-label">{{currentPlan}}</div>
            <div id="plan-link" v-if="!hasPlan"><a target="blank" href="https://orbitalstack.com/pricing/" >{{planOptionsText}}</a></div>
            <div id="plan-link" v-if="hasPlan && isUserOrgAdmin"><router-link :to="{ name: 'CompanyAdministration' }">{{planListText}}</router-link></div>
          </div>

          <div id="credits-group">
            <div id="remaining-ai">
              <div class="remaining-title">AI credits remaining: {{aiCreditsLeft}}</div>
              
              <div v-if="hasAICredits" class="full-bar">
                <div class="filled-bar" :style="aiCreditsBarWidth"></div>
                <div class="credits-fraction">{{aiCreditsLeft}}/{{aiInitialCredits}}</div>
              </div>
              <div v-else class="full-grey-bar"></div>

              <div v-if="hasAICredits" class="expiring-date" :style="aiExpireColor">{{aiCreditsToExpire}}</div>
            </div>
            <div id="remaining-cfd">
              <div class="remaining-title">CFD credits remaining: {{cfdCreditsLeft}}</div>
              
              <div v-if="hasCFDCredits" class="full-bar">
                <div class="filled-bar" :style="cfdCreditsBarWidth"></div>
                <div class="credits-fraction">{{cfdCreditsLeft}}/{{cfdInitialCredits}}</div>
              </div>
              <div v-else class="full-grey-bar"></div>
              
              <div v-if="hasCFDCredits" class="expiring-date" :style="cfdExpireColor">{{cfdCreditsToExpire}}</div>
            </div>
          </div>

          <div id="vertical-line"></div>

          <div id="links-group">
            <div id="interested" v-if="isUserViewer">Interested in trying out OS?</div>
            <div class="link" :style="isStandAloneLink" id="contact-us"><a target="blank" href="https://orbitalstack.com/get-in-touch/">Contact Us</a></div>
            <div class="link" v-if="isUserNew && !isUserViewer"><a target="blank" href="mailto:support@orbitalstack.com">Book a demo</a></div>
            <div class="link" v-if="isUserOrgAdmin && !isUserNew && !hasPlan"><router-link :to="{ name: 'CompanyAdministration' }">Credit Details</router-link></div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  GET_COMPANY_SUBSCRIPTIONS
} from '@/store/actions/permissions';

export default {
  name: 'MarketingBar',
  components: {},
  pageTitle: 'Projects',
  computed: {
    isLoading() {
      return this.$store.getters['project/loading'];
    },
    currentPlan() {
      return this.currentPlanName;
    },
    planOptionsText() {
      if (this.currentPlanName === 'Viewer') {
        return 'Plan Options';
      } else {
        return 'Upgrade Plan';
      }
    },
    hasAICredits() {
      return this.aiCreditsLeft > 0;
    },
    hasCFDCredits() {
      return this.cfdCreditsLeft > 0;
    },
    isUserViewer() {
      return this.currentPlanName === 'Viewer';
    },
    isUserOnlyFreeTrial() {
      return this.currentPlanName === 'Free Trial';
    },
    isUserOrgAdmin() {
      return this.loggedInUser.role === 'role: org admin';
    },
    isStandAloneLink() {
      if (!this.isUserViewer && !(this.isUserNew && !this.isUserViewer) && !(this.isUserOrgAdmin && !this.isUserNew && !this.hasPlan)) {
        return 'margin-top: 0.8rem!important;';
      }
      return '';
    },
    hasNoPlanLink() {
      if (this.hasPlan && !(this.hasPlan && this.isUserOrgAdmin)) {
        return 'margin-top: 0.4rem!important;';
      }
      return '';
    },
    ...mapGetters(['companySubscriptions','loggedInUser', 'userCompany'])
  },
  async created() {
    await this.$store.dispatch(GET_COMPANY_SUBSCRIPTIONS);

    this.setUserName();
    if (this.loggedInUser.role === 'role: viewer') {
      this.currentPlanName = 'Viewer';
    } else {
      this.checkForAICredits();
      this.checkForCFDCredits();
    }
  },
  data() {
    return {
      userFirstName: '',
      isUserNew: false,
      hasPlan: false,
      currentPlanName: '',
      planListText: 'See Company Plans',
      aiCreditsLeft: 0,
      aiInitialCredits: 0,
      aiCreditsBarWidth: '',
      aiCreditsToExpire: '',
      aiExpireColor: '',
      cfdCreditsLeft: 0,
      cfdInitialCredits: 0,
      cfdCreditsBarWidth: '',
      cfdCreditsToExpire: '',
      cfdExpireColor: ''
    };
  },
  methods: {
    checkForAICredits() {
      let subscription = [];
      let aiSubCredits = this.companySubscriptions?.filter(sub =>
        sub.subscription_type.type === 'MONTHLY'
        && sub.subscription_type.credit_type === 'AI'
        && sub.credits_used_this_month < sub.subscription_type.monthly_wind_directions);
      if (this.loggedInUser?.subscription) {
        aiSubCredits.forEach(credit => {
          if (credit.id == this.loggedInUser.subscription) {
            subscription.push(credit);
          }
        });
      }
      if (aiSubCredits.length > 0 && subscription.length == 0) {
        this.hasPlan = true;
      }

      let aiDetails = [];
      if (subscription.length > 0) {
        aiDetails = this.setCreditDetails(subscription, 'monthly');
        this.aiCreditsToExpire = `Renews monthly on the ${subscription[0].monthly_roll_over_day}${this.ordinalSufix(subscription[0].monthly_roll_over_day)}`;
        this.aiExpireColor = 'color: #1376C0;';
      } else {
        let aiCredits = this.companySubscriptions?.filter(sub =>
          sub.subscription_type.type === 'ONE-TIME'
          && sub.subscription_type.credit_type === 'AI'
          && sub.total_credits_used < sub.pay_per_use_credits);
        if (aiCredits.length == 0) {
          // this company pay_per_use_enabled
          if (this.userCompany?.pay_per_use_enabled == true){
            this.currentPlanName = 'Pay Per Use';
          } else {
            this.currentPlanName = 'Prepaid';
          }
          return;
        }
        aiDetails = this.setCreditDetails(aiCredits, 'one-time');
        if (aiDetails['currentPlan'] == 'Free Trial') {
          this.isUserNew = true;
        }
        if (!aiDetails['noExpiryDate']) {
          this.aiExpireColor = aiDetails['expiryCreditsColor'];
          this.aiCreditsToExpire = `${aiDetails['creditsToExpire']} credits expire ${aiDetails['expiryDate']}`;
        }
      }

      this.aiCreditsLeft = aiDetails['creditsLeft'];
      this.aiInitialCredits = aiDetails['allCredits'];
      this.aiCreditsBarWidth = aiDetails['creditsBarWidth'];
      if (aiDetails['currentPlan']=='Free Trial' && this.hasPlan) {
        this.currentPlanName = 'Prepaid';
      } else {
        this.currentPlanName = aiDetails['currentPlan'];
      }
    },
    checkForCFDCredits() {
      let cfdCredits = this.companySubscriptions?.filter(sub =>
        sub.subscription_type.credit_type === 'CFD'
        && sub.total_credits_used < sub.pay_per_use_credits);
      
      if (cfdCredits.length > 0){
        let cfdDetails = this.setCreditDetails(cfdCredits, 'one-time');

        this.cfdCreditsLeft = cfdDetails['creditsLeft'];
        this.cfdInitialCredits = cfdDetails['allCredits'];
        this.cfdCreditsBarWidth = cfdDetails['creditsBarWidth'];
        if (!cfdDetails['noExpiryDate']) {
          this.cfdCreditsToExpire = `${cfdDetails['creditsToExpire']} credits expire ${cfdDetails['expiryDate']}`;
          this.cfdExpireColor = cfdDetails['expiryCreditsColor'];
        }
        if (this.currentPlanName == 'Free Trial' && cfdDetails['currentPlan'] !== 'Free Trial') {
          this.currentPlanName = cfdDetails['currentPlan'];
        }
      }
    },
    setUserName() {
      this.userFirstName = this.loggedInUser?.first_name;
    },
    setCreditDetails(credits, subscriptionType) {

      let used = 0;
      let all = 0;
      let plan = 'Free Trial';
      let creditsWithExpiryDate = 0;
      let daysToExpire = 0;
      let formattedExpiryDate = '';
      let nextCreditsToExpire = 0;
      let noExpiryDate = true; 
      credits.forEach(credit => {
        if (credit.expiry_date) {
          let expiryDate = new Date(credit.expiry_date);
          let days = Math.round((expiryDate - new Date()) / (1000 * 60 * 60 * 24));
          if (creditsWithExpiryDate == 0 || (creditsWithExpiryDate > 0 && days < daysToExpire)) {
            daysToExpire = days;
            let slicedDate = expiryDate.toISOString('UTC', {month: 'numeric', day: 'numeric', year: '2-digit'}).substring(0,10).split(/-/);
            formattedExpiryDate = `${slicedDate[1]}/${slicedDate[2]}/${slicedDate[0]}`;
            nextCreditsToExpire = credit.pay_per_use_credits - credit.total_credits_used;
          }
          noExpiryDate = false;
          creditsWithExpiryDate += 1;
        }

        if (subscriptionType === 'one-time') {
          used += credit.total_credits_used;
          all += credit.pay_per_use_credits;
          if (credit.subscription_type.tier === 'Pay-per-use') {
            plan = 'Prepaid';
          } else if (credit.subscription_type.tier === 'Free' && credit.subscription_type.credit_type === 'AI') {
            let startDate = new Date(credit.start_date);
            let trialDays = Math.round((new Date() - startDate) / (1000 * 60 * 60 * 24));
            if (trialDays > 30) {
              plan = 'Prepaid';
            }
          }
        } else {
          used = credit.credits_used_this_month;
          all = credit.subscription_type.monthly_wind_directions;
          plan = `${credit.subscription_type.tier} Subscription`;
        }  
        
      });

      let creditsLeft = all - used;
      let percentage = (creditsLeft * 100) / all;
      let color = '';
      if (daysToExpire < 5) {
        color = 'color: #F62828;';
      } else {
        color = 'color: #1376C0;';
      }
      let details = {
        'currentPlan': plan,
        'creditsLeft': all - used,
        'allCredits': all,
        'creditsBarWidth': `width:${(percentage/100) * 15}rem;`,
        'expiryCreditsColor': color,
        'expiryDate': formattedExpiryDate,
        'creditsToExpire': nextCreditsToExpire,
        'noExpiryDate': noExpiryDate
      };

      return details;

    },
    ordinalSufix(number) {
      return ['th', 'st', 'nd', 'rd'][(number > 3 && number < 21) || number % 10 > 3 ? 0 : number % 10];
    }
  }
};
</script>

<style scoped>
#header-image {
  display: flex;
}

#bg-image {
  background-image: url('~@/assets/img/mkt-bar-bg-ft-geometry.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 12rem;
}

#overlay {
  position: absolute;
  background-color: #0173b9;
  mix-blend-mode: multiply;
  width: 100%;
  height: 12rem;
  margin-top: 0;
}

#user-bar {
  transition: all 200ms ease-in-out;
  width: calc(100% - (1.5em + 1vw));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  max-width: 75rem;
  margin: auto;
  margin-top: -7.5rem;
}

#greetings {
  color:  var(--white);
  font-weight: 600;
  font-size: 1.38rem;
  line-height: 2rem;
  font-style: normal;
  font-family: 'Inter';
  overflow: hidden;
  position: relative;
  margin:auto;
  margin-bottom: 1.3rem;
  width: calc(100% - (1.5em + 1vw));
  max-width: 75rem;
}

.mkt-bar {
  background-color: var(--white);
  box-shadow: 0rem 0.100rem 0.313rem var(--grey-500);
  border-radius: 0.5rem;
  display: flex; 
  transition: all 200ms ease-in-out;
  min-height: 6rem;
  margin: 0 0 0 0.8rem;
  overflow: hidden;
  position: relative;
  width: calc(100% - (1.3em + 1vw));
  max-width: 75rem;
  padding-bottom: 0.3rem;
}

.mkt-bar div {
  margin-top: 0;
}

#plan-group {
  margin: 1.8em 0 0 2.8rem;
}

#current-plan {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 0.95rem;
  line-height: 1.2rem;
  align-items: center;
  color: #0C1F29;
}

#plan-label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1rem;
  align-items: center;
  color: #1376C0;
  margin: 0.4rem 0 0.4rem 0;
}

#plan-link {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 0.9rem;
  align-items: center;
  text-decoration-line: underline;
  color: #259FE4;
}

#plan-link a {
  color: #259FE4;
}

#credits-group {
  display: flex;
  margin: 1.8rem 0 0 2rem;
}

#remaining-cfd, #remaining-ai {
  margin-left: 4rem;
}

.remaining-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 0.95rem;
  line-height: 1.2rem;
  align-items: center;
  color: #0C1F29;
}

.full-bar {
  background: #F4FBFF;
  border: 0.05rem solid #1376C0;
  border-radius: 0.45rem;
  width: 15rem;
  display: flex;
  margin: 0.5em 0 0.5em 0 !important;
}

.full-grey-bar {
  background-color: #D9D9D9;
  border-radius: 0.45rem;
  width: 15rem;
  height: 0.7rem;
  display: flex;
  margin: 0.5rem 0 0.5rem 0 !important;
}

.filled-bar {
  height: 0.7rem;
  background-color: #66C2FF;
  border-radius: 0.45rem;
}

.credits-fraction {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 0.67rem;
  line-height: 0.68rem;
  color: #09538A;
  width: 15rem;
  text-align: center;
  position: absolute;
}

.expiring-date {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 0.7rem;
  line-height: 0.95rem;
  align-items: center;
  color: #6C757D;
}

#vertical-line {
  border-left: 0.1rem solid rgb(226, 230, 233);
  height: 4rem;
  margin: 1.8rem 2.5rem 1rem 3.5rem;
  padding-bottom: 0.5rem;
}

#links-group {
  margin: 2.3em 0 0 0;
}

.link {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 0.95rem;
  line-height: 1.2rem;
  align-items: center;
  text-decoration-line: underline;
  color: #259FE4;
}

.link a {
  text-decoration: none;
  color: #259FE4;
}

#interested, #contact-us {
  margin-bottom
  : 0.3rem;
}

@media screen and (max-width: 75rem) {
  #vertical-line {
    margin: 1.8rem 1.5rem 1rem 1.5rem;
    padding-bottom: 0.5rem;
  }
  #credits-group {
    margin-left: 2.2rem;
  }
}

@media screen and (max-width: 72rem) {
  #remaining-cfd, #remaining-ai {
    margin-left: 2rem;
  }
  #vertical-line {
    margin: 1.8rem 1rem 0.5rem 1.5rem;
  }
  #credits-group {
    margin-left: 2rem;
  }
}

@media screen and (max-width: 67.2rem) {
  #plan-label {
    font-size: 1.1rem;
  }

  #credits-group {
    margin-left: 1.6rem;
  }

  .link {
    font-size: 0.85rem;
  }
}


@media screen and (max-width: 61rem) {
  .mkt-bar {
    padding: 0;
  }
  #plan-group {
    margin: 1.1rem 0 0 1.4rem;
  }
  #credits-group {
    margin: 1.1rem 0 0 1.3rem;
  }
  #remaining-cfd, #remaining-ai {
    margin-left: 1rem;
  }
  #links-group {
    margin: 1.6em 0 0 0;
  }
  #vertical-line {
    margin: 1.2rem 1rem 0.5rem 1.5rem;
    height: 3rem;
    padding-bottom: 0.5rem;
  }

  #current-plan {
    font-size: 0.8rem;
  }
  #plan-label {
    font-size: 0.9rem;
  }
  #plan-link {
    font-size: 0.7rem;
  }

  .remaining-title {
    font-size: 0.8rem;
  }
  .expiring-date {
    font-size: 0.65rem;
  }

  .link {
    font-size: 0.75rem;
  }

  .full-bar, .full-grey-bar {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  #plan-label {
    margin-top: 0.1rem !important;
    margin-bottom: 0.18rem !important;
  }
}

</style>